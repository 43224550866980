
import api from '@/utilities/api'
import { Component, Vue, Watch } from 'vue-property-decorator'
import DisputeModal from '@/pages/costConfirmation/disputes/DisputeModal'
import { headers, items } from '@/pages/costConfirmation/disputes/helpers/disputes'
import { DEFAULT_DAY_RANGES, DISPUTE_STATUSES } from '@/utilities/constants'
import { DateTime } from 'luxon'
import cloneDeep from 'lodash/cloneDeep'
import { title } from '@/utilities/filters'

const {
  ALL_OPEN,
  NEW,
  AWAITING_RESPONSE_FROM_SUPPLIER,
  CLOSED,
  REFERRED_TO_SUPPLIER_RELATIONS,
  REFERRED_TO_OPERATIONS,
  RESPONSE_RECEIVED_FROM_SUPPLIER,
  REFERRED_TO_PRICING_MANAGER
} = DISPUTE_STATUSES

const { now } = DateTime

@Component({ components: { 'c-dispute-modal': DisputeModal } })
export default class DisputesPage extends Vue {
  // data // ***
  dates: DateTime[] = [now().minus({ days: DEFAULT_DAY_RANGES.DISPUTES }), now()]
  editingDispute: Record<string, any> | null = null
  entryTypes: Record<string, any>[] = []
  expenseTypes: Record<string, any>[] = []
  filterFields: Record<string, any> = { status: ALL_OPEN, cmacRef: null, externalId: null }
  modalOpen = false
  status = ALL_OPEN

  // computed // ***
  get activeSuppliers(): Record<string, any>[] {
    return this.$store.getters['suppliers/activeSuppliers'].map((v: number) => this.suppliers[v])
  }
  get activeSupplierIds(): string[] {
    return this.activeSuppliers.map((v: Record<string, any>) => v.id)
  }
  get disputes(): Record<string, any>[] {
    return this.$store.getters['suppliers/disputes']
  }
  get filterFieldProps() {
    return {
      cmacRef: { label: this.$t('cmac.cmacRef') },
      externalId: { label: this.$t('cmac.yourRef') },
      status: {
        items: [
          { text: title(String(this.$t('cmac.allOpen'))), value: ALL_OPEN },
          { text: title(String(this.$t('common.new'))), value: NEW },
          {
            text: title(String(this.$t('cmac.awaitingResponseFromSupplier'))),
            value: AWAITING_RESPONSE_FROM_SUPPLIER,
          },
          {
            text: title(String(this.$t('cmac.closed'))),
            value: CLOSED,
          },
          {
            text: title(String(this.$t('cmac.referredToSupplierRelations'))),
            value: REFERRED_TO_SUPPLIER_RELATIONS,
          },
          {
            text: title(String(this.$t('cmac.referredToPricingManager'))),
            value: REFERRED_TO_PRICING_MANAGER,
          },
          {
            text: title(String(this.$t('cmac.referredToOperations'))),
            value: REFERRED_TO_OPERATIONS,
          },
          {
            text: title(String(this.$t('cmac.responseReceivedFromSupplier'))),
            value: RESPONSE_RECEIVED_FROM_SUPPLIER,
          }
        ],
      },
    }
  }
  get headers(): Record<string, any>[] {
    return headers(this)
  }
  get items(): Record<string, any>[] {
    return items(this)
  }
  get loading(): boolean {
    const apiEndpointIsLoading = this.$store.getters['core/apiEndpointIsLoading']
    return Object.values(this.urls).reduce((a: boolean, v: string): boolean => {
      if (a === true) return true
      else return apiEndpointIsLoading({ method: 'GET', url: v })
    }, false)
  }
  get fetchLoading(): boolean {
    return this.$store.getters['core/apiEndpointIsLoading']({
      method: 'GET',
      url: this.urls.disputes,
    })
  }
  get params() {
    const { dates } = this
    const supplierOrganisationIds =
      this.activeSuppliers.length >= 1 ? this.activeSupplierIds : [String(this.supplier?.id)]
    const dateFrom = dates[0].toISODate()
    const dateTo = this.dates[1]?.toISODate() || cloneDeep(dates[0]).toISODate()
    return {
      customer: null,
      dateFrom,
      dateTo,
      disputeStatus: this.filterFields.status,
      supplierOrganisationIds,
    }
  }
  get supplier(): Record<string, any> {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  get suppliers(): Record<string, any>[] {
    return this.$store.getters['suppliers/suppliers']
  }
  get urls() {
    return {
      disputes: 'supplier/disputesbydeparture',
      entryTypes: 'supplier/entryTypes',
      expenseTypes: 'referencedata/allexpensetypes',
    }
  }

  // created // ***
  created() {
    this.fetch()
  }

  // watch // ***
  @Watch('supplier')
  onSupplierChanged(n: Record<string, any> | undefined, o: Record<string, any> | undefined): void {
    if (n && o?.id !== n.id) this.fetch()
  }

  // methods // ***
  onEditClicked(e: any) {
    this.modalOpen = !this.modalOpen
    this.editingDispute = e?.item
  }
  fetch() {
    this.fetchDisputes()
    this.fetchExpenseTypes()
    this.fetchEntryTypes()
  }
  async fetchDisputes() {
    const { params, urls } = this
    const url = urls.disputes
    await this.$store.dispatch('suppliers/fetchDisputes', { params, url })
  }
  async fetchEntryTypes() {
    const { data } = (await api(this.urls.entryTypes, undefined, undefined)) || {}
    if (data) this.entryTypes = data
  }
  async fetchExpenseTypes() {
    const { data } = (await api(this.urls.expenseTypes, undefined, undefined)) || {}
    if (data) this.expenseTypes = data
  }
  onFieldClicked(e: any) {
    this.onEditClicked(e)
  }
  onModalInput(e: boolean) {
    if (!e) this.editingDispute = null
  }
  onSearch(): void {
    this.fetchDisputes()
  }
}
