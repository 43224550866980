
import { Component, Prop, Vue } from 'vue-property-decorator'

import { DISPUTE_ENTRY_TYPES } from '@/utilities/constants'
import api from '@/utilities/api'

@Component
export default class AddDisputeEntryModal extends Vue {
  // props // ***
  @Prop({ required: true, type: Object })
  dispute!: Record<string, any>
  @Prop({ required: true, type: Array })
  entryTypes!: Record<string, any>[]
  @Prop({ type: Boolean })
  value!: boolean

  // data // ***
  description = ''
  files = []

  // computed // ***
  get entryType(): number {
    const { files } = this
    return files.length > 0
      ? DISPUTE_ENTRY_TYPES.FileUploadFromSupplier
      : DISPUTE_ENTRY_TYPES.COMMENT_FROM_SUPPLIER
  }
  get loading(): boolean {
    return this.$store.getters['core/apiEndpointIsLoading']({
      method: 'POST',
      url: `supplier/dispute/${this.dispute.id}/entries`,
    })
  }
  get params(): Record<string, any> {
    const { description, dispute, entryType } = this
    return {
      description,
      supplierCostConfirmationDisputeEntryTypeId: entryType,
      supplierCostConfirmationDisputeId: dispute.id,
    }
  }
  get valid(): boolean {
    return true
  }

  // methods // ***
  clearFields(): void {
    this.description = ''
    this.files = []
  }
  onCancel(): void {
    this.clearFields()
    this.$emit('input', false)
  }
  onSubmit(): void {
    if (this.valid) this.storeDisputeEntry()
  }
  storeDisputeEntry(): void {
    var formData = new FormData()
    formData.append('description', this.params.description)
    formData.append(
      'supplierCostConfirmationDisputeEntryTypeId',
      this.params.supplierCostConfirmationDisputeEntryTypeId
    )
    formData.append(
      'supplierCostConfirmationDisputeId',
      this.params.supplierCostConfirmationDisputeId
    )

    for (var i = 0; i < this.files.length; i++) formData.append(`files[${i}]`, this.files[i])

    api(
      `supplier/dispute/${this.dispute.id}/entries`,
      {
        method: 'POST',
        data: formData,
        formData: true,
      },
      undefined
    )
      .then((): void => {
        this.$emit('fetch-dispute-entries')
        this.clearFields()
        this.$emit('input', false)
      })
      .catch((e: Record<string, any>): void => {
        console.error(e)
      })
  }
}
