
import { Component, Prop, Vue } from 'vue-property-decorator'
import { formatCurrency } from '@/utilities/functions'
import { getCurrency } from '@/utilities/views'
import { title } from '@/utilities/filters'
import { disableSorting } from '@/utilities/org'

@Component
export default class ExpensesPanel extends Vue {
  // props // ***
  @Prop({ required: true, type: Object })
  dispute!: Record<string, any>
  @Prop({ required: true, type: Array })
  expenses!: Record<string, any>[]

  // computed // ***
  get items(): Record<string, any>[] {
    return this.expenses
      .filter((e: Record<string, any>) => Number(e?.costNet) > 0)
      .map(e => ({
        ...e,
        cost: formatCurrency(e.costNet, getCurrency(e.costCurrency.id)),
        ref: `E${e.id}`,
        type: title(e.expense.name),
      }))
  }
  get headers(): Record<string, any>[] {
    return disableSorting([
      { text: 'Ref', value: 'ref' },
      { text: this.$t('common.type'), value: 'type' },
      { text: this.$t('common.comments'), value: 'comments' },
      { text: this.$t('common.cost'), value: 'cost' },
    ])
  }
}
