import { title } from '@/utilities/filters'
import { getCmacRef } from '@/utilities/org'
import { DISPUTE_STATUSES as DS } from '@/utilities/constants'
import { getFormattedDateTime } from '@/utilities/org'

const {
  ALL_OPEN,
  NEW,
  AWAITING_RESPONSE_FROM_SUPPLIER,
  CLOSED,
  REFERRED_TO_SUPPLIER_RELATIONS,
  REFERRED_TO_OPERATIONS,
  RESPONSE_RECEIVED_FROM_SUPPLIER,
  REFERRED_TO_PRICING_MANAGER
} = DS

const OPEN_STATUSES = [
  NEW,
  AWAITING_RESPONSE_FROM_SUPPLIER,
  REFERRED_TO_OPERATIONS,
  REFERRED_TO_SUPPLIER_RELATIONS,
  RESPONSE_RECEIVED_FROM_SUPPLIER,
]

export const headers = (context: Record<string, any>): Record<string, any>[] => {
  return [
    { text: String(context.$t('cmac.cmacReference')), value: 'cmacRef' },
    { text: title(String(context.$t('cmac.yourReference'))), value: 'externalId' },
    {
      renderFunction: (d: string): string => getFormattedDateTime(d),
      text: title(String(context.$t('cmac.lastUpdated'))),
      value: 'updatedDateUtc',
    },
    {
      renderFunction: (d: string): string => getFormattedDateTime(d),
      text: title(String(context.$t('common.created'))),
      value: 'created',
    },
    { text: title(String(context.$t('cmac.actionedBy'))), value: 'actionedBy' },
    {
      renderFunction: (s: string): string => {
        switch (s) {
          case NEW:
            return title(String(context.$t('common.new')))
          case AWAITING_RESPONSE_FROM_SUPPLIER:
            return title(String(context.$t('cmac.awaitingResponseFromSupplier')))
          case CLOSED:
            return title(String(context.$t('cmac.closed')))
          case REFERRED_TO_SUPPLIER_RELATIONS:
            return title(String(context.$t('cmac.referredToSupplierRelations')))
          case REFERRED_TO_OPERATIONS:
            return title(String(context.$t('cmac.referredToOperations')))
          case RESPONSE_RECEIVED_FROM_SUPPLIER:
            return title(String(context.$t('cmac.responseReceivedFromSupplier')))
          case REFERRED_TO_PRICING_MANAGER:
            return title(String(context.$t('cmac.referredToPricingManager')))
          default:
            return title(String(context.$t('common.unknown')))
        }
      },
      text: title(String(context.$t('common.status'))),
      value: 'status',
    },
    { text: title(String(context.$t('common.comments'))), value: 'comments' },
    {
      vueComponent: {
        name: 'c-table-action-button',
        props: { class: 'my-2', color: 'primary', small: true, text: context.$t('common.details') },
      },
      value: '',
      width: '1%',
    },
  ]
}

export const items = (context: Record<string, any>): Record<string, any>[] => {
  return context.disputes
    .filter((d: Record<string, any>): boolean => {
      const status = context.filterFields.status
      if (status !== ALL_OPEN && d.status !== status) return false
      if (status === ALL_OPEN && !OPEN_STATUSES.includes(d.status)) return false

      const cmacRefFilter = context.filterFields.cmacRef
      if (cmacRefFilter && !getCmacRef(d).includes(cmacRefFilter)) return false

      const externalId = context.filterFields.externalId
      if (externalId && (!d.externalId || !d.externalId.includes(externalId))) return false

      return true
    })
    .map(
      (d: Record<string, any>): Record<string, any> => ({
        ...d,
        cmacRef: getCmacRef(d),
        assignedOrganisation: d.supplier?.name,
        comments: d.supplierComments,
        created: d.createdDateUtc,
        customerOrganisation: d.customer?.name,
      })
    )
}
