
import AddDisputeEntryModal from '@/pages/costConfirmation/disputes/AddDisputeEntryModal'
import { config as env } from '@/plugins/env'
import { title } from '@/utilities/filters'
import { disableSorting, getFormattedDateTime } from '@/utilities/org'
import { Component, Prop, Vue } from 'vue-property-decorator'

const isDisputed = (d: any, o: any): boolean => !((!d && d !== 0) || d === o)

@Component({ components: { 'c-add-dispute-entry-modal': AddDisputeEntryModal } })
export default class DisputeDetailsPanel extends Vue {
  // props // ***
  @Prop({ required: true, type: Object })
  counts!: Record<string, number>
  @Prop({ required: true, type: Object })
  dispute!: Record<string, any>
  @Prop({ required: true, type: Array })
  entries!: Record<string, any>[]
  @Prop({ required: true, type: Array })
  entryTypes!: Record<string, any>[]
  @Prop({ required: true, type: Array })
  existingExpenses!: Record<string, any>[]
  @Prop({ required: true, type: Number })
  leadMileageCalculationType!: number

  // data // ***
  showAddEntryModal = false

  // computed // ***
  get detailsItems(): Record<string, any>[] {
    const { expenses: items, leadMileageItem: lm, waitingTimeMinutesItem: wtm } = this
    if (wtm) items.push(wtm)
    if (lm) items.push(lm)
    return items
  }
  get entriesItems(): Record<string, any>[] {
    return this.entries.map(e => ({
      ...e,
      comments: e.description,
      date: getFormattedDateTime(e.createdDateUtc),
      user: e.createdBy,
    }))
  }
  get items(): Record<string, Record<string, any>[]> {
    const commentItems = [{ comment: this.originalComments }]
    return { details: this.detailsItems, comment: commentItems, entries: this.entriesItems }
  }
  get headers(): Record<string, any> {
    return {
      details: disableSorting([
        { text: '', value: 'name' },
        { text: this.$t('common.original'), value: 'original' },
        { text: this.$t('cmac.disputed'), value: 'disputed' },
        { text: this.$t('common.status'), value: 'status' },
      ]),
      comment: disableSorting([{ text: this.$t('common.comment'), value: 'comment' }]),
      entries: disableSorting([
        { text: this.$t('common.date'), value: 'date' },
        { text: this.$t('common.comments'), value: 'comments' },
        { text: this.$t('common.user'), value: 'user' },
        { value: 'documents' },
      ]),
    }
  }
  get leadMileageItem(): Record<string, any> | null {
    const { dispute } = this
    const { disputedLeadMileage: d, originalLeadMileage: o } = dispute
    if (!isDisputed(d, o)) return null
    return {
      jobEntryId: dispute.jobEntryId,
      isLeadMileage: true,
      disputed: d,
      name: title(String(this.$t('cmac.leadMileage'))),
      original: o,
      status: title(String(this.$t('common.pending'))),
    }
  }
  get originalComments(): string {
    const { dispute, entries } = this
    const initialCommentEntry = entries.find((e: Record<string, any>): boolean => e.title === title)
    const entryComment = initialCommentEntry?.description || null
    return dispute.comments || entryComment || ''
  }
  get expenses(): Record<string, any>[] {
    const { dispute, existingExpenses } = this
    const { expenses } = dispute
    const expensesToMatch = existingExpenses.map(x => {
      return {
        expenseTypeId: x.expense?.id,
        costNet: x.costNet,
      }
    })
    if (!expenses || expenses.length === 0) return []
    return expenses.map((x: any) => {
      let existingMatch = expensesToMatch.find(
        e => e.expenseTypeId === x.expenseTypeId && e.costNet === x.disputeValue
      )
      return {
        disputed: x.disputeValue,
        name: `${x.expenseDescription}${x.description ? ` - ${x.description}` : ''}`,
        original: x.originalValue,
        status: existingMatch
          ? title(String(this.$t('common.accepted')))
          : title(String(this.$t('common.pending'))),
      }
    })
  }
  get waitingTimeMinutesItem(): Record<string, any> | null {
    const { dispute } = this
    const { disputedWaitingTimeMinutes: d, originalWaitingTimeMinutes: o } = dispute
    if (!isDisputed(d, o)) return null
    return {
      disputed: d,
      name: title(String(this.$t('cmac.waitingTimeMinutes'))),
      original: o,
      status: title(String(this.$t('common.pending'))),
    }
  }
  get rootApiUrl(): string {
    return env.API_ROOT_URL
  }
}
