
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import { getFormattedTimeForDispute } from '@/pages/costConfirmation/disputes/helpers/shared'
import { getFormattedDateTime } from '@/utilities/org'
import { title } from '@/utilities/filters'

@Component
export default class JourneysPanel extends Vue {
  // props // ***
  @Prop({ required: true, type: Object })
  dispute!: Record<string, any>
  @Prop({ required: true, type: Array })
  journeys!: Record<string, any>[]

  // computed // ***
  get farePriceCalculations() {
    return this.$store.getters['suppliers/farePriceCalculations']
  }
  get keyClasses(): string {
    return 'grey--text  text-right py-1 flex-grow-1'
  }

  // mounted // ***
  mounted() {
    this.fetch()
  }

  // watch // ***
  @Watch('journeys')
  onJourneysChanged() {
    this.fetch()
  }

  // methods // ***
  fetch() {
    this.journeys.forEach(j => this.fetchFarePriceCalculation(j))
  }
  fetchFarePriceCalculation(journey: Record<string, any>) {
    const url = this.getFarePriceCalculationUrl(journey)
    this.$store.dispatch('suppliers/fetchFarePriceCalculation', { id: journey.id, url })
  }
  getFarePriceCalculation(journey: Record<string, any>) {
    return this.farePriceCalculations[journey.id] || {}
  }
  getFarePriceCalculationUrl(journey: Record<string, any>) {
    return `supplier/price/${journey.id}`
  }
  getFarePriceCalculationLoading(journey: Record<string, any>) {
    const url = this.getFarePriceCalculationUrl(journey)
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'GET', url })
  }
  journeyDetailsItems(j: Record<string, any>): Record<string, any> {
    return {
      [this.$t('cmac.cmacRef') as string]: `${this.dispute.jobId}:${j.id}`,
      [this.$t('cmac.supplierRef') as string]: j.externalId,
      [this.$t('cmac.pickupDateTime') as string]: getFormattedDateTime(j.departureDateOffset),
      [title(this.$t('cmac.passenger') as string)]: j.leadPassengerName,
      [this.$t('cmac.noOfPax') as string]: j.pax,
      [title(this.$t('cmac.vehicleType') as string)]: j.vehicle?.name,
      [this.$t('common.miles') as string]: j.mileage,
    }
  }
  timesTableItems(j: Record<string, any>): Record<string, any> {
    const parsedOffset = DateTime.fromISO(j.departureDateOffset)
    return {
      ETA: getFormattedTimeForDispute(j.eta, parsedOffset),
      ATA: getFormattedTimeForDispute(j.ata, parsedOffset),
      POB: getFormattedTimeForDispute(j.pob, parsedOffset),
      DO: getFormattedTimeForDispute(j.dof, parsedOffset),
      SD: getFormattedTimeForDispute(j.sdn, parsedOffset),
    }
  }
}
