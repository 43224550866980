import { DISPUTE_STATUSES } from '@/utilities/constants'
import { DateTime } from 'luxon'
import { getFormattedTime } from '@/utilities/org'

export const getDisputeStatus = (s: number): string => {
  // TODO: i18n
  const { ALL, NEW, CLOSED, REFERRED_TO_SUPPLIER_RELATIONS, REFERRED_TO_OPERATIONS, REFERRED_TO_PRICING_MANAGER } =
    DISPUTE_STATUSES
  switch (s) {
    case ALL:
      return 'All'
    case NEW:
      return 'New'
    case CLOSED:
      return 'Closed'
    case REFERRED_TO_SUPPLIER_RELATIONS:
      return 'Referred to Supplier Relations'
    case REFERRED_TO_OPERATIONS:
      return 'Referred to Operations'
    case REFERRED_TO_PRICING_MANAGER:
      return 'Referred to Pricing Manager'
    default:
      return 'Unknown'
  }
}

export const getFormattedTimeForDispute = (t: number, o: DateTime): string =>
  o && t !== null ? getFormattedTime(o.plus({ seconds: t }).toISO()) : ''

export const getTotalDuration = (wp: Record<string, any>[]): string => {
  let total = 0
  wp.forEach((w: Record<string, any>): void => {
    if (w.duration) total += Number.parseInt(w.duration)
  })
  return String(Math.round(total / 60))
}
