
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import api from '@/utilities/api'
import DisputeDetailsPanel from '@/pages/costConfirmation/disputes/DisputeDetailsPanel'
import ExpensesPanel from '@/pages/costConfirmation/disputes/ExpensesPanel'
import JourneysPanel from '@/pages/costConfirmation/disputes/JourneysPanel'

@Component({
  components: {
    'c-dispute-details-panel': DisputeDetailsPanel,
    'c-expenses-panel': ExpensesPanel,
    'c-journeys-panel': JourneysPanel,
  },
})
export default class DisputesModal extends Vue {
  // props // ***
  @Prop({ required: true, type: Object })
  dispute!: Record<string, any>
  @Prop({ required: true, type: Array })
  entryTypes!: Record<string, any>[]
  @Prop({ required: true, type: Array })
  expenseTypes!: Record<string, any>[]
  @Prop({ type: Boolean })
  value!: boolean

  // data // ***
  colors: Record<string, any> = {
    // content: 'grey lighten-1',
    // header: 'grey lighten-1',
  }
  disputeEntries: Record<string, any>[] = []
  job: Record<string, any> | null = null
  jobHistory: Record<string, any> | null = null
  jobHistoryPageSize = 100
  journeys: Record<string, any> = {
    bonusPayments: [],
    expenses: [],
    hotels: [],
    journeys: [],
    retainers: [],
  }
  openPanels: (number | string)[] = [0, 1, 2, 3]

  // computed // ***
  get canFetch() {
    return this.value && this.supplier?.id
  }
  get counts(): Record<string, number> {
    return {
      disputeEntries: this.disputeEntries?.length || 0,
      expenses: this.journeys?.expenses?.length || 0,
      journeys: this.journeys?.journeys?.length || 0,
    }
  }
  get jobId() {
    return this.dispute.jobId
  }
  get supplier() {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  get urls() {
    const { dispute, jobHistoryPageSize, jobId } = this
    return {
      disputeEntries: `supplier/dispute/${dispute.id}/entries?supplierUser=true`,
      job: `supplier/job/${jobId}`,
      jobHistory: `jobhistory/search?jobId=${jobId}&pageSize=${jobHistoryPageSize}&pageIndex=0`,
      journeys: `job/getJourneyForDispute?id=${dispute.id}&organisationId=${this.supplier.id}`,
    }
  }
  get leadMileageCalculationType() {
    if (!this.journeys || !this.journeys.journeys || this.journeys.journeys.length === 0) return 0
    return this.journeys.journeys[0].leadMileageCalculationType
  }

  @Watch('supplier')
  onSupplierChange() {
    if (this.canFetch) this.fetch()
  }

  // created // ***
  created() {
    if (this.canFetch) this.fetch()
  }

  // methods // ***
  async fetch() {
    await this.fetchJob()
    await this.fetchJourneys()
    // TODO: job history might be needed in the future, but for now should be omitted
    // await this.fetchJobHistory()
    await this.fetchDisputeEntries()
  }
  async fetchDisputeEntries() {
    const { data } = (await api(this.urls.disputeEntries, undefined, undefined)) || {}
    if (data) this.disputeEntries = data
  }
  async fetchJob() {
    const { data } = (await api(this.urls.job, undefined, undefined)) || {}
    if (data) this.job = data
  }
  async fetchJobHistory() {
    const { data } = (await api(this.urls.jobHistory, undefined, undefined)) || {}
    if (data) this.jobHistory = data
  }
  async fetchJourneys() {
    const { data } = (await api(this.urls.journeys, undefined, undefined)) || {}
    if (data) this.journeys = data
  }
}
